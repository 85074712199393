export default defineNuxtRouteMiddleware(to => {
  if (process.client) return

  if (/^\/(articles|blog|boutique\/jeu-de-cartes|evenements|je-minspire|jeu-de-cartes|wp-content\/uploads)(\/.*)?$/.test(to.path)) {
    return navigateTo(`https://media.profilpublic.fr${to.path}`, { external: true, redirectCode: 301 })
  }

  if (to.path.startsWith('/qui-sommes-nous') || to.path.startsWith('/employeur/profil-public-2') || to.path.startsWith('/etudes') || to.path.startsWith('/marque-employeur-service-public-comment-se-demarquer-pour-attirer-et-fideliser-les-talents')) {
    return navigateTo('/employeurs/profil-public', { redirectCode: 301 })
  }

  if (to.path.startsWith('/conditions-generales-dutilisation-2')) {
    return navigateTo('/conditions-generales', { redirectCode: 301 })
  }

  if (to.path.startsWith('/espace-employeur')) {
    return navigateTo('/ats', { redirectCode: 301 })
  }

  if (to.path.startsWith('/je-suis-recruteur')) {
    return navigateTo('/nos-services', { redirectCode: 301 })
  }

  if (to.path.startsWith('/jeu-de-cartes-bonus')) {
    return navigateTo('https://media.profilpublic.fr/jeu-de-cartes-bonus', { external: true, redirectCode: 301 })
  }

  if (to.path.startsWith('/faq')) {
    return navigateTo('https://profil-public.zendesk.com/hc/fr', { external: true, redirectCode: 301 })
  }

  if (to.path.startsWith('/politique-de-confidentialite') || to.path.startsWith('/terms')) {
    return navigateTo('/politique-de-donnees', { redirectCode: 301 })
  }

  if (to.path.startsWith('/etude')) {
    return navigateTo('/employeurs/profil-public', { redirectCode: 301 })
  }

  if (to.path.startsWith('/job/')) {
    return navigateTo('/jobs', { redirectCode: 301 })
  }

  if (to.path.startsWith('/jobs/')) {
    const urls = {
      'technicienne-dexploitation/': 'technicien-informatique-and-audiovisuel-f-h',
      'alternance-notre-plus-belle-plume-f-h/': 'alternance-charge-de-contenu-editorial-and-innovation-publique-f-h',
      'alternance-business-developer/': 'alternance-business-developer-f-h',
      'alternance-notre-super-social-media-manager-f-h/': 'alternance-super-social-media-manager-f-h',
      'chef%c2%b7fe-de-service-adjoint-emploi-et-competences/': 'chef-de-service-adjoint-emploi-et-competences-f-h',
      'un·e-chef·fe-de-service-adjoint·e-carrieres-et-remunerations-h-f/': 'chef-de-service-adjoint-carrieres-et-remuneration-f-h',
      'responsable-administratif-h-f/': 'responsable-administratif-f-h',
      'consultant-senior-en-transformation-du-secteur-public/': 'consultant-senior-transformation-du-secteur-public-f-h',
      'directriceeur-des-services-techniques/': 'directeur-des-services-techniques-f-h-2',
      'consultant-e-senior-en-organisation-et-strategie-pour-le-secteur-public-local/':
        'consultant-senior-organisation-et-strategie-du-secteur-public-f-h',
      'cheffe-de-projet-experte-achats-socialement-responsables/': 'chef-de-projet-achats-socialement-responsables-f-h',
      'responsable-achats-et-commande-publique/': 'responsable-achats-and-commande-publique-f-h',
      'consultant-experimente-strategie-rh-secteur-public-local-f-h': 'consultant-senior-strategie-rh-f-h',
      'cheffe-de-projet-numerique-h-f/': 'chef-de-projet-numerique-f-h',
      'responsable-developpement-economique-h-f/': 'responsable-developpement-economique-f-h',
      'directeur-des-batiments-intercommunaux/': 'directeur-des-batiments-intercommunaux-f-h',
      'data-scientist-data-engineer-h-f/': 'data-scientist-f-h-2',
      'directeur-rice-de-la-direction-de-laccompagnement-a-la-transition-ecologique/': 'directeur-accompagnement-a-la-transition-ecologique-f-h',
      'directeur-rice-de-la-mediatheque-departementale/': 'directeur-mediatheque-departementale-f-h',
      'assistant-de-direction-h-f/': 'assistant-de-direction-f-h-1',
      '3-chargees-de-projet-au-departement-devaluation-des-formations-def/': 'charge-de-projet-evaluation-des-formations-f-h',
      'rssi-responsable-de-la-securite-des-systemes-dinformations-h-f/': 'responsable-securite-des-systemes-d-informations-f-h',
      'juriste-fonction-publique-et-action-sociale-f-h/': 'juriste-fonction-publique-and-action-sociale-f-h',
      'chef%c2%b7fe-du-service-pilotage-de-la-qualite-comptable/': 'chef-du-service-pilotage-de-la-qualite-comptable-f-h',
      'dae-chef-fe-de-projet-modernisation-de-la-fonction-achat-h-f/': 'chef-de-projet-modernisation-de-la-fonction-achat-f-h',
      'charge-de-mission-allocation-de-ressources-hospitaliere-et-ambulatoire-f-h/':
        'charge-de-mission-allocation-ressources-hospitalieres-and-ambulatoire-f-h',
      'directeur-de-developpement-local/': 'directeur-de-developpement-local-f-h',
      'coordinateur-de-contrats-et-daffaires-f-h/': 'coordinateur-de-contrats-and-affaires-f-h',
      'juriste-statutaire-h-f': 'juriste-statutaire-f-h',
      'redacteur-redactrice-etat-civil-a-la-division-de-la-protection/':
        'redacteur-etat-civil-a-la-division-de-la-protection-asile-and-apatridie-f-h',
      'officier-officiere-de-protection-instructeur-instructrice-opi/': 'redacteur-etat-civil-a-la-division-de-la-protection-asile-and-apatridie-f-h',
      'consultant-experimente-transition-ecologique-des-territoires-f-h/': 'consultant-senior-transition-ecologique-des-territoires-f-h',
      'consultant-e-experimente-e-manager-strategie-et-transformation-digitale-secteur-public-local/':
        'consultant-senior-transformation-numerique-f-h',
      'stage-de-pre-embauche-consultante-en-organisation-et-strategie-pour-le-secteur-public-local/':
        'stage-pre-embauche-i-consultant-organisation-and-strategie-secteur-public-f-h',
      'educateur-de-jeunes-enfants-direction-de-la-premiere-enfance/': 'educateur-de-jeunes-enfants-f-h'
    }

    const slug = to.path.replace('/jobs/', '')
    if (Object.keys(urls).includes(slug)) {
      return navigateTo(`/jobs/${urls[slug]}`, { redirectCode: 301 })
    }
  }

  if (to.path.startsWith('/employeur/')) {
    const slug = to.path.replace('/employeur/', '')
    return navigateTo(`/employeurs/${slug}`, { redirectCode: 301 })
  }

  if (to.path.startsWith('/ils-recrutent')) {
    return navigateTo('/employeurs', { redirectCode: 301 })
  }
})
